import React, {useEffect, useState} from 'react';
import {Button, Card, List} from "antd";
import {useParams} from "react-router-dom";
import {RouteParams} from "../../../layout";
import {useDispatch, useSelector} from "react-redux";
import {
    customersActions,
    CustomersReducerType,
    ICustomer,
    getCustomersTC
} from "../../../../redux/reducers/customerReducer";
import {ModalWindowCustomer} from "../../../../components/ModalWinows/ModalWindowCustomer";
import {RootStateType} from "../../../../redux/store";
import {EditOutlined} from "@ant-design/icons";

export const Customer = () => {

    const {customers, customer} = useSelector<RootStateType, CustomersReducerType>(state => state.customers)

    const {customerUrlId} = useParams<RouteParams>()
    const [isShowUpdate, setIsShowUpdate] = useState<boolean>(false);

    const dispatch = useDispatch()

    useEffect(() => {
        if (customerUrlId) {
            dispatch(getCustomersTC(customerUrlId))
        }
    }, [dispatch, customerUrlId])


    const editCustomer = () => {
        setIsShowUpdate(false);
    };

    const setDataModal = (customer: ICustomer) => {
        dispatch(customersActions.setDataCustomer(customer));
        setIsShowUpdate(true);
    };

    return (
        <>
            {isShowUpdate && (<ModalWindowCustomer
                    title={"Редактирование пользователя"}
                    onClick={editCustomer}
                    customer={customer}
                    isShow={isShowUpdate}
                    setIsShow={setIsShowUpdate}
                />
            )}
            <List
                grid={{gutter: 16, column: 1}}
                dataSource={customers}
                renderItem={(customer: ICustomer) => (
                    <List.Item>
                        <Card title="Данные пользователя:">
                            <div>
                                <div style={{marginBottom: "10px"}}>Имя: {customer.firstname}</div>
                                <div style={{marginBottom: "10px"}}>Фамилия: {customer.lastname}</div>
                                <div style={{marginBottom: "10px"}}>Дата Рождения: {customer.birthDate}</div>
                                <div style={{marginBottom: "10px"}}>Email: {customer.email}</div>
                                <div style={{marginBottom: "10px"}}>Телефон: {customer.phone}</div>
                                <div style={{marginBottom: "10px"}}>Facebook
                                    ID: {customer.facebookId ? customer.facebookId : 'Нет данных'}</div>
                                <div style={{marginBottom: "10px"}}>Google
                                    ID: {customer.googleId ? customer.facebookId : 'Нет данных'}</div>
                                <div style={{marginBottom: "10px"}}>Account non
                                    expired: {customer.accountNonExpired ? "true" : "false"}</div>
                                <div style={{marginBottom: "10px"}}>Account non
                                    locked: {customer.accountNonLocked ? "true" : "false"}</div>
                                <div style={{marginBottom: "10px"}}>Credentials non
                                    expired: {customer.credentialsNonExpired ? "true" : "false"}</div>
                            </div>
                            <Button type="primary" icon={<EditOutlined/>} onClick={() => setDataModal(customer)}>
                                Изменить данные
                            </Button>
                        </Card>
                    </List.Item>
                )}
            />
        </>
    );
};
export enum ServiceDelivery {
    belPost = 'BELPOST',
    euroPost = "EUROPOCHTA"
}

export interface IAddress {
    city: string
    country: string
    description: string
    id: number
    phone: string
    state: string
    username: string
    zip: string
}

export interface IOrder {
    id: number
    status: null | string
    timeAdd: string
    amount: number
    address: IAddress
    username: string
}

export interface IOrderAdmin {
    id: number
    status: null | string
    timeAdd: string
    amount: number
    address: IAddress
    comment: string
    serviceDelivery: ServiceDelivery
    trackNumber: string
}

import {InferActionsType, BaseThunkType} from "../store"
import {sizeAPI} from "../../api/admin/sizeAPI"
import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer"
import {ISize} from "../../interfaces/Size";

type ThunkType = BaseThunkType<ActionsType>
export type ActionsType = InferActionsType<typeof sizeActions>
export type SizeReducerType = typeof initialState

const initialState = {
    sizes: [] as ISize[],
};

export const SizeReducer = (state = initialState, action: ActionsType): SizeReducerType => {
    switch (action.type) {
        case "ADMIN/SET-SIZES":
            return {...state, sizes: action.sizes,}
        default:
            return state
    }
}

export const sizeActions = {
    setSizesAC: (sizes: ISize[]) =>
        ({type: "ADMIN/SET-SIZES", sizes} as const),
}

export const getSizesTC = (categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const sizes = await sizeAPI.getSizes(categoryId)
        dispatch(sizeActions.setSizesAC(sizes.data.content))
    }, dispatch)
}
export const addSizeTC = (name: string, categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await sizeAPI.addSize(name, categoryId)
        await dispatch(getSizesTC(categoryId))
        setNotification(NotificationType.success, "Размер успешно добавлен")
    }, dispatch)
}
export const getSizeTC = (sizeId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await sizeAPI.getSize(sizeId)
    }, dispatch)
}
export const updateSizeTC = (sizeId: number, name: string, categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await sizeAPI.updateSize(sizeId, name, categoryId)
        await dispatch(getSizesTC(categoryId))
        setNotification(NotificationType.success, "Размер успешно изменен")
    }, dispatch)
}

export const deleteSizeTC = (sizeId: number, categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await sizeAPI.deleteSize(sizeId)
        await dispatch(getSizesTC(categoryId))
        setNotification(NotificationType.success, "Размер успешно удален")
    }, dispatch)
}

import React, {ChangeEvent, useState} from 'react'
import {Input, Modal, Select} from "antd";
import {useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store";
import {INewOrderData} from "../../../../api/admin/orderApi";
import {IOrderAdmin, ServiceDelivery} from "../../../../interfaces/Order";

interface IOrderModalProps {
    title: string
    isShowModal: boolean
    setIsShowModal: (isShowModal: boolean) => void
    onClick: (orderData: INewOrderData) => void
}

export const OrderModal = (props: IOrderModalProps) => {
    const {Option} = Select
    const order = useSelector<RootStateType, IOrderAdmin>(state => state.orders.order)

    const [comment, setComment] = useState(order.comment)
    const [serviceDelivery, setServiceDelivery] = useState(order.serviceDelivery)
    const [trackNumber, setTrackNumber] = useState(order.trackNumber)

    const orderData = {
        comment: comment,
        serviceDelivery: serviceDelivery,
        trackNumber: trackNumber,
    }

    const handleOk = () => {
        handleCancel()
        props.onClick(orderData)
    }
    const handleCancel = () => {
        props.setIsShowModal(false)
    }
    const onChangeTrackNumber = (e: ChangeEvent<HTMLInputElement>) => {
        setTrackNumber(e.currentTarget.value)
    }
    const onChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
        setComment(e.currentTarget.value)
    }
    const onChangeServiceDelivery = (value: any) => {
        setServiceDelivery(value)
    }

    return (
        <Modal
            title={props.title}
            visible={props.isShowModal}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <div>Номер трека:</div>
            <Input value={trackNumber}
                   placeholder={"Номер трека"}
                   onChange={onChangeTrackNumber}
                   style={{width: "100%", marginBottom: 20}}/>
            <div>Коментарий:</div>
            <Input value={comment}
                   onChange={onChangeComment}
                   placeholder={"Коментарий"}
                   style={{width: "100%", marginBottom: 20}}
            />
            <div>Способ доставки:</div>
            <Select
                value={serviceDelivery}
                onChange={onChangeServiceDelivery}
                style={{width: "100%", marginBottom: 20}}
            >
                    <Option key={ServiceDelivery.belPost} value={ServiceDelivery.belPost}>
                        {ServiceDelivery.belPost}
                    </Option>
            </Select>
        </Modal>
    )
}

import {BaseThunkType, InferActionsType} from "../store";
import {colourAPI} from "../../api/admin/colorAPI";
import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer";
import { IColor } from "../../interfaces/Colour";

type ThunkType = BaseThunkType<ActionsType>;
export type ActionsType = InferActionsType<typeof coloursActions>;
export type ColourReducerType = typeof initialState;

const initialState = {
    colours: [] as IColor[],
};

export const ColourReducer = (state = initialState, action: ActionsType): ColourReducerType => {
    switch (action.type) {
        case "ADMIN/SET-COLOURS":
            return {...state, colours: action.payload.colours};
        default:
            return state;
    }
};

export const coloursActions = {
    setColoursAC: (colours: IColor[]) =>
        ({type: "ADMIN/SET-COLOURS", payload: {colours},} as const),
}

export const getColoursTC = (categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const colours = await colourAPI.getColours(categoryId);
        dispatch(coloursActions.setColoursAC(colours.data.content))
    }, dispatch)
}
export const addColourTC = (categoryId: number, name: string, code: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await colourAPI.addColour(categoryId, name, code)
        await dispatch(getColoursTC(categoryId))
        setNotification(NotificationType.success, "Цвет успешно добавлен")
    }, dispatch)
};
export const getColourTC = (id: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const colour = await colourAPI.getColour(id)
        dispatch(coloursActions.setColoursAC(colour.data.name))
    }, dispatch)
}
export const updateColorTC = (id: number, categoryId: number, name: string, code: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await colourAPI.updateColour(id, categoryId, name, code)
        await dispatch(getColoursTC(categoryId))
        setNotification(NotificationType.success, "Цвет успешно изменен")
    }, dispatch)
}
export const deleteColorTC = (id: number, categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await colourAPI.deleteColour(id)
        await dispatch(getColoursTC(categoryId))
        setNotification(NotificationType.success, "Цвет успешно удален")
    }, dispatch)
}

import React, {useState} from 'react';
import {MODAL_TYPE} from "../../utils/constants";
import {Button, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {ModalWindow} from "./components/Modal";
import {ISize} from "../../interfaces/Size";

interface IProps {
    sizes: ISize[]
    deleteItem: (id: number) => void
    addSize: (name: string) => void
    editSize: (name: string, item?: ISize) => void
    openModal: () => void
}
const {Column} = Table;

export const Layout = ({sizes, addSize, editSize, deleteItem, openModal}: IProps) => {
    const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.ADD)
    const [currentSize, setCurrentSize] = useState<ISize | null>()

    const openModalByTypeHandler = (type: MODAL_TYPE, record?: ISize) => {
        openModal()
        setModalType(type)
        setCurrentSize(record)
    }

    return (
        <>
            <Button style={{marginBottom: 20}}
                    type='primary'
                    onClick={() => openModalByTypeHandler(MODAL_TYPE.ADD)}
            >
                Добавить размер
            </Button>
            {modalType === MODAL_TYPE.ADD &&
            <ModalWindow title={"Добавить размер"} onClick={addSize}/>}
            {modalType === MODAL_TYPE.EDIT &&
            <ModalWindow title={"Редактирование размера"} onClick={editSize} item={currentSize}/>}

            <Table rowKey={"id"} dataSource={sizes}>
                <Column title='ID' dataIndex='id' key='id'/>
                <Column title='Размер' dataIndex='name' key='name'/>
                <Column title='Action' key='action'
                        render={(text, record: ISize) => (
                            <Space size='middle' key={record.id}>
                                <Button icon={<EditOutlined/>}
                                        onClick={() => openModalByTypeHandler(MODAL_TYPE.EDIT, record)}
                                        type={"primary"}
                                />
                                <Button onClick={() => deleteItem(record.id)}
                                        icon={<DeleteOutlined/>} type={"primary"}
                                        danger/>
                            </Space>
                        )}
                />
            </Table>
        </>
    );
};

import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer"
import {BaseThunkType, InferActionsType} from "../store"
import {customerApi} from "../../api/admin/customerApi"

type ThunkType = BaseThunkType<ActionsType>
export type ActionsType = InferActionsType<typeof customersActions>
export type CustomersReducerType = typeof initialState

export interface ICustomer {
    accountNonExpired?: boolean
    accountNonLocked?: boolean
    birthDate?: string
    credentialsNonExpired?: boolean
    email?: string
    enabled?: boolean
    facebookId?: number
    firstname?: string
    githubId?: number
    googleId?: number
    lastname?: string
    phone?: string
}

const initialState = {
    customers: [] as ICustomer[],
    customer: {} as ICustomer
}

export const customerReducer = (state = initialState, action: ActionsType): CustomersReducerType => {
    switch (action.type) {
        case "ADMIN/SET-CUSTOMERS":
            return {...state, customers: action.payload.customers}
        case "ADMIN/SET-CUSTOMER-DATA":
            return {...state, ...action.payload}
        default:
            return state
    }
}

export const customersActions = {
    setOrdersAC: (customers: ICustomer[]) =>
        ({type: "ADMIN/SET-CUSTOMERS", payload: {customers}} as const),
    setDataCustomer: (customer: ICustomer) =>
        ({type: "ADMIN/SET-CUSTOMER-DATA", payload: {customer}} as const),
}

export const getCustomersTC = (email: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const customers = await customerApi.getCustomers(email)
        dispatch(customersActions.setOrdersAC(customers.data.content))
    }, dispatch)
}

export const updateCustomersTC = (userId: string, customer: ICustomer): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await customerApi.updateCustomer(userId, customer)
        setNotification(NotificationType.success, "Данные пользователя успешно изменены")
    }, dispatch)
}
import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer"
import {INewOrderData, orderApi} from "../../api/admin/orderApi"
import {BaseThunkType, InferActionsType} from "../store"
import {IOrder, IOrderAdmin} from "../../interfaces/Order";

type ThunkType = BaseThunkType<ActionsType>
export type ActionsType = InferActionsType<typeof orderActions>
export type OrderReducerType = typeof initialState

const initialState = {
    orders: [] as IOrder[],
    totalElements: 0 as number,
    order: {} as IOrderAdmin,
    status: '' as string | null
};

export const orderReducer = (state = initialState, action: ActionsType): OrderReducerType => {
    switch (action.type) {
        case "ADMIN/SET-ORDERS":
            return {
                ...state,
                orders: action.payload.orders,
                totalElements: action.payload.totalElements
            }
        case "ADMIN/SET-ORDER":
            return {
                ...state,
                order: action.payload.order,
                status: action.payload.order.status,
            }
        case "ADMIN/SET_STATUS":
            return {...state, ...action.payload}
        default:
            return state
    }
}

export const orderActions = {
    setOrdersAC: (orders: Array<IOrder>, totalElements: number) =>
        ({type: "ADMIN/SET-ORDERS", payload: {orders, totalElements}} as const),
    setOrderAC: (order: IOrderAdmin) =>
        ({type: "ADMIN/SET-ORDER", payload: {order}} as const),
    setStatusAC: (status: string) =>
        ({type: "ADMIN/SET_STATUS", payload: {status}} as const),
}

export const getOrdersTC = (username?: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const orders = await orderApi.getOrders(username)
        dispatch(orderActions.setOrdersAC(orders.data.content, orders.data.totalElements));
    }, dispatch)
}
export const getOrderTC = (order_id: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const order = await orderApi.getOrder(order_id)
        dispatch(orderActions.setOrderAC(order.data))
    }, dispatch)
}
export const updateOrderTC = (order_id: number, orderData: INewOrderData): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await orderApi.updateOrder(order_id, orderData)
        await dispatch(getOrderTC(order_id))
        setNotification(NotificationType.success, "Заказ успешно изменен")
    }, dispatch)
}

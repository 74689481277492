import {itemsAPI} from "../../api/admin/itemsAPI"
import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer"
import {BaseThunkType, InferActionsType} from "../store"
import {IItem, IItems, ISearchItem } from "../../interfaces/Item"

type ThunkType = BaseThunkType<ActionsType>
export type ActionsType = InferActionsType<typeof itemsActions>
export type ItemsReducerType = typeof initialState

const initialState = {
    items: [] as IItems[],
    item: {} as IItem,
    searchResult: [] as ISearchItem[],
    title: "",
}

export const itemsReducer = (state = initialState, action: ActionsType): ItemsReducerType => {
    switch (action.type) {
        case "admin/SET_ITEMS":
            return {...state, items: action.items,}
        case "admin/SET_ITEM":
            return {...state, item: action.item,}
        case "admin/SET_SEARCH_RESULTS":
            return {...state, searchResult: action.payload.searchResult}
        case "admin/SET_TITLE":
            return {...state, ...action.payload,}
        default:
            return state
    }
}

export const itemsActions = {
    setItemsAC: (items: IItems[]) =>
        ({type: "admin/SET_ITEMS", items} as const),
    setItemAC: (item: IItem) => ({type: "admin/SET_ITEM", item} as const),
    setNameItemsForSearchAC: (title: string) =>
        ({type: "admin/SET_TITLE", payload: {title}} as const),
    setSearchResultsAC: (searchResult: ISearchItem[]) =>
        ({type: "admin/SET_SEARCH_RESULTS", payload: {searchResult}} as const),
}

export const getItemsTC = (categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const items = await itemsAPI.getItems(categoryId)
        dispatch(itemsActions.setItemsAC(items.data.content))
    }, dispatch)
}
export const addItemsTC = (name: string, description: string, categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await itemsAPI.addItem(name, description, categoryId)
        await dispatch(getItemsTC(categoryId))
        setNotification(NotificationType.success, "Товар успешно добавлен")
    }, dispatch)
}
export const updateItemsTC = (id: number, name: string, description: string, categoryId: number, orderPoint: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await itemsAPI.updateItem(id, name, description, categoryId, orderPoint)
        await dispatch(getItemsTC(categoryId))
        setNotification(NotificationType.success, "Товар успешно изменен")
    }, dispatch)
}
export const deleteItemsTC = (id: number, categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await itemsAPI.deleteItem(id)
        await dispatch(getItemsTC(categoryId))
        setNotification(NotificationType.success, "Товар успешно удален")
    }, dispatch)
}
export const findItemTC = (id: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const item = await itemsAPI.findItem(id)
        dispatch(itemsActions.setItemAC(item.data))
    }, dispatch)
}
export const swapItemsTC = (firstId: number, secondId: number, categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await itemsAPI.swapItems(firstId, secondId)
        await dispatch(getItemsTC(categoryId))
    }, dispatch)
}
export const searchItemsTC = (searchValue: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const searchResults = await itemsAPI.search(searchValue)
        dispatch(itemsActions.setSearchResultsAC(searchResults.data.content))
    }, dispatch)
}


import React, {useState} from 'react';
import {ImageWithEmpty} from "../../components/Image";
import {NavLink} from "react-router-dom";
import {Button, Space, Table} from "antd";
import {DeleteOutlined, DownOutlined, EditOutlined, UpOutlined} from "@ant-design/icons";
import {ModalWindow} from "./components/Modal";
import {MODAL_TYPE} from "../../utils/constants";
import {IItems} from "../../interfaces/Item";

interface IProps {
    items: IItems[]
    categoryId: number
    openModal: () => void
    deleteItem: (id: number) => void
    swapItemUp: (id: number) => void
    swapItemDown: (id: number) => void
    addItem: (name: string, description: string) => void
    editItem: (name: string, description: string, item?: IItems) => void
}

const {Column} = Table;

export const Layout = ({
                           items,
                           categoryId,
                           openModal,
                           deleteItem,
                           swapItemUp,
                           swapItemDown,
                           addItem,
                           editItem
                       }: IProps) => {
    const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.ADD)
    const [currentItem, setCurrentItem] = useState<IItems | null>()

    const openModalByTypeHandler = (type: MODAL_TYPE, record?: IItems ) => {
        openModal()
        setModalType(type)
        setCurrentItem(record)
    }

    return (
        <>
            {modalType === MODAL_TYPE.ADD  && <ModalWindow title={"Добавить товар"} onClick={addItem}/>}
            {modalType === MODAL_TYPE.EDIT  && <ModalWindow title={"Редактировать товар"} onClick={editItem} item={currentItem}/>}
            <Button style={{marginBottom: 20}} type='primary' onClick={() => openModalByTypeHandler(MODAL_TYPE.ADD)}>
                Добавить товар
            </Button>
            <Table rowKey={"itemId"} dataSource={items}>
                <Column title='ID' dataIndex='itemId' key='itemId' sorter={(a: any, b: any) => a.itemId - b.itemId}/>
                <Column title='Изображение' dataIndex='image' key='image'
                        render={(text, record: IItems) => (
                            <ImageWithEmpty path={record.webPath}/>
                        )}
                />
                <Column title='Название товара' dataIndex='name' key='name'
                        sorter={(a: IItems, b: IItems) => a.name.localeCompare(b.name)}
                        render={(name, key: IItems) => (
                            <NavLink to={`/admin/price/${categoryId}/${key.itemId}`}>{name}</NavLink>
                        )}
                />
                <Column title='Описание' dataIndex='description' key='description'/>
                <Column title='Минимальная цена' dataIndex='minPrice' key='minPrice'/>
                <Column title='Максимальная цена' dataIndex='maxPrice' key='maxPrice'/>
                <Column title='Action' key='action'
                        render={(text, record: IItems) => (
                            <Space size='middle' key={record.itemId}>
                                <Button icon={<EditOutlined/>} onClick={() => openModalByTypeHandler(MODAL_TYPE.EDIT, record)}
                                        type={"primary"}/>
                                <Button onClick={() => deleteItem(record.itemId)} icon={<DeleteOutlined/>}
                                        type={"primary"} danger/>
                                <Button icon={<UpOutlined/>} type={"primary"}
                                        onClick={() => swapItemUp(record.itemId)}/>
                                <Button icon={<DownOutlined/>} type={"primary"}
                                        onClick={() => swapItemDown(record.itemId)}/>
                            </Space>
                        )}
                />
            </Table>
        </>

    );
};


import "antd/dist/antd.css"
import React, {useEffect} from "react"
import {Route, Switch} from "react-router-dom"
import {Login} from "./ui/3-content/auth/login"
import {LayoutComponent} from "./ui/layout"
import {PrivateRoute} from "./utils/PrivateRoute"
import {authMeTC} from "./redux/reducers/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "./redux/store";
import {NotFoundPage} from "./components/NotFoundPage/NotFoundPage"


export const App = () => {
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    const dispatch = useDispatch()
    useEffect(() => {
        !isAuth && dispatch(authMeTC())
    }, [dispatch, isAuth])

    return (

        <Switch>
            <PrivateRoute exact path={"/"} component={LayoutComponent}/>
            <PrivateRoute path={"/admin"} component={LayoutComponent}/>
            <Route path={'/login'} render={() => <Login/>}/>
            <Route component={NotFoundPage} />
        </Switch>

    )
}

import {instanceEmptyPath} from "../instance";

export const authAPI = {
    login(username: string, password: string) {
        const data = new FormData()
        data.append('username', username)
        data.append('password', password)
        return instanceEmptyPath.post("/api/auth/login", data)
    },
    authMe() {
        return instanceEmptyPath.get("/api/auth/user")
    },
};

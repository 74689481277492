import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {RouteParams} from "../../ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {
    addImageToPriceTC,
    addItemPriceTC,
    changeMailImageTC,
    deleteImageFromPriceTC,
    getItemPriceTC,
    PriceReducerType,
    removePriceTC
} from "../../redux/reducers/priceReducer";
import {Layout} from "./Layout";
import {commonActions} from "../../redux/reducers/commonReducer";
import {UploadRequestOption as RcCustomRequestOptions} from "rc-upload/lib/interface";
import {IColor} from "../../interfaces/Colour";
import { ISize } from '../../interfaces/Size';
import { IItem } from '../../interfaces/Item';
import { INewPrice } from '../../interfaces/Price';

export const Price = () => {
    const {categoryUrlId} = useParams<RouteParams>()
    const {itemUrlId} = useParams<RouteParams>()
    const categoryId = Number(categoryUrlId)
    const itemId = Number(itemUrlId)
    const dispatch = useDispatch()

    const {prices, uniqueColors} = useSelector<RootStateType, PriceReducerType>(state => state.price);
    const colours = useSelector<RootStateType, IColor[]>(state => state.colour.colours)
    const sizes = useSelector<RootStateType, ISize[]>(state => state.size.sizes)
    const item = useSelector<RootStateType, IItem>(state => state.items.item)

    useEffect(() => {
        dispatch(getItemPriceTC(itemId, categoryId))
    }, [dispatch, categoryId, itemId])

    const addPrice = (newPrice: INewPrice) => {
        dispatch(addItemPriceTC(newPrice));
    };
    const removePrice = (id: number) => {
        dispatch(removePriceTC(id, itemId));
    };
    const uploadImg = (photo: RcCustomRequestOptions, colorId: number) => {
        const file = photo.file
        dispatch(addImageToPriceTC(colorId, file, itemId))
    }
    const deleteImage = (imageId: number) => {
        dispatch(deleteImageFromPriceTC(imageId, itemId))
    }
    const changeMainImage = (imageId: number) => {
        dispatch(changeMailImageTC(imageId, itemId))
    }
    const openModal = () => {
        dispatch(commonActions.setIsVisibleModalAC(true))
    }
    return (
        <Layout prices={prices}
                uniqueColors={uniqueColors}
                colours={colours}
                sizes={sizes}
                openModal={openModal}
                addPrice={addPrice}
                removePrice={removePrice}
                deleteImage={deleteImage}
                changeMainImage={changeMainImage}
                item={item}
                uploadImg={uploadImg}
        />
    );
};

import React from 'react'
import {Button, Card} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {IPhotos} from "../../../interfaces/Item";

interface IProps {
    photo: IPhotos
    deleteImage: (imageId: number) => void
    changeMainImage: (imageId: number) => void
}

export const PriceTabImageCard = ({photo, deleteImage, changeMainImage}: IProps) => {
    const currentImageButtonColor = photo.main ? "green" : ''
    return (
        <Card
            actions={[
                <Button onClick={() => changeMainImage(photo.id)} type='primary'
                        style={{padding: 0, width: 120, background: currentImageButtonColor}}>
                    {photo.main ? 'Главная' : 'Сделать главной'}
                </Button>,
                <Button onClick={() => deleteImage(photo.id)} type='primary' danger
                        icon={<DeleteOutlined/>} style={{width: 120}}/>
            ]}
            style={{width: 250, margin: " 20px 20px 20px 0", display: "grid", alignItems: "end"}}
            cover={<img alt="price img" src={"http://shop-qa.republic-club.by" + photo.path}/>}
        />
    )
}

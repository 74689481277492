import {Table} from "antd";
import React from "react";
import {useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {NavLink} from "react-router-dom";
import {ImageWithEmpty} from "../../../components/Image";
import {ISearchItem} from "../../../interfaces/Item";
import { ICategory } from "../../../interfaces/Category";


export const SearchResults = () => {
    const {Column} = Table;
    const searchResults = useSelector<RootStateType, ISearchItem[]>(state => state.items.searchResult)
    const categories = useSelector<RootStateType, ICategory[]>(state => state.categories.categories)
    return (
        <>
            <Table rowKey={"itemId"} dataSource={searchResults}>
                <Column
                    title='ID'
                    dataIndex='itemId'
                    key='itemId'
                    sorter={(a: any, b: any) => a.itemId - b.itemId}
                />
                <Column
                    title='Изображение'
                    dataIndex='image'
                    key='image'
                    render={(text, record: ISearchItem) => (
                        <ImageWithEmpty path={record.webPath} />
                    )}
                />
                <Column title='Категория' key='categoryId'
                        render={(text, record: ISearchItem) => {
                            let category = categories.find(c => c.id === record.categoryId)
                            if (category) {
                                return <div>{category.name}</div>
                            }
                        }}
                />
                <Column
                    title='Название'
                    dataIndex='name'
                    key='name'
                    sorter={(a: any, b: any) => a.name.localeCompare(b.name)}
                    render={(name, key: ISearchItem) => (
                        <NavLink to={`/price/${key.categoryId}/${key.itemId}`}>{name}</NavLink>
                    )}
                />
                <Column title='Описание' dataIndex='description' key='description'/>
            </Table>
        </>
    )
}

import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Layout, Menu} from "antd";
import logo from '../../assets/images/rc.svg'
import "../../App.css";
import {menuItems} from "../../utils/constants";

export const Navbar = () => {
    const {Sider} = Layout;
    const keyPath = sessionStorage.getItem('menuItem')
    const [key, setKey] = useState<string>(keyPath || '1')

    const selectedItem = (keyPath: any) => {
        setKey(keyPath.toString())
    }
    useEffect(() => {
        if (key)
            sessionStorage.setItem('menuItem', key)
    }, [key])

    return (
        <Sider style={{overflow: "auto", height: "100vh", position: "fixed", left: 0,}}>
            <div className='logo'>
                <img alt={'logo'} className='logoImg' src={logo}/>
            </div>
            <Menu theme='dark' mode='inline' defaultSelectedKeys={[key]}>
                {
                    menuItems.map((i, id) => <Menu.Item
                        onClick={({key}) => selectedItem(key)} key={id}
                    >
                        <NavLink to={i.link}>{i.name}</NavLink>
                    </Menu.Item>)
                }
            </Menu>
        </Sider>
    );
}

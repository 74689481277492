import React from 'react'
import emptyPhoto from "../assets/images/no-photo.png";
import {Image} from "antd";

interface IImageWithEmpty {
    path?: string | null
}

export const ImageWithEmpty = ({path}: IImageWithEmpty) => {
    return (
        <Image
            width={100}
            src={path ? "http://shop-qa.republic-club.by" + path : emptyPhoto}
        />
    )
}
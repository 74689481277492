import {Table} from "antd";
import Search from "antd/lib/input/Search";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {getOrdersTC, OrderReducerType} from "../../../../redux/reducers/orderReducer";
import {RootStateType} from "../../../../redux/store";
import {IOrder} from "../../../../interfaces/Order";


export const OrdersTable = () => {
    const {Column} = Table;

    const {orders} = useSelector<RootStateType, OrderReducerType>(state => state.orders)
    const [userName, setUserName] = useState<string>('')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOrdersTC(userName))
    }, [dispatch, userName])


    const onSearch = (searchValue: string) => {
        setUserName(searchValue)
    }
    return (
        <>
            <Search
                placeholder='Введите имя пользователя'
                enterButton
                onSearch={onSearch}
            />
            <Table rowKey='id' dataSource={orders}>
                <Column title='id' dataIndex='id' key='id'/>
                <Column title='Email/Username'
                        dataIndex='username'
                        key='username'
                        render={(name, key: IOrder) => (
                            <NavLink to={`/admin/shop/order/${key.id}`}>{key.username}</NavLink>
                        )}
                />
                <Column title='Телефон'
                        dataIndex='address'
                        key='phone'
                        render={(name, key: IOrder) => (
                            <>{key.address.phone}</>
                        )}
                />
                <Column title='Количество' dataIndex='amount' key='amount'/>
                <Column title='Дата' dataIndex='timeAdd' key='timeAdd'/>
                <Column title='Статус' dataIndex='status' key='status'/>
            </Table>
        </>
    );
};

import React, {useEffect} from 'react';
import {Layout} from "./Layout";
import {useParams} from "react-router-dom";
import {RouteParams} from "../../ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {
    addColourTC,
    ColourReducerType,
    deleteColorTC,
    getColoursTC,
    updateColorTC
} from "../../redux/reducers/colourReducer";
import {commonActions} from "../../redux/reducers/commonReducer";
import { IColor } from '../../interfaces/Colour';

export const Colours = () => {
    const {categoryUrlId} = useParams<RouteParams>();
    const categoryId = Number(categoryUrlId);
    const dispatch = useDispatch();

    const {colours} = useSelector<RootStateType, ColourReducerType>(state => state.colour);

    useEffect(() => {
        dispatch(getColoursTC(categoryId));
    }, [dispatch, categoryId]);

    const addColour = (name: string, code: string) => {
        dispatch(addColourTC(categoryId, name, code));
    };
    const deleteColour = (id: number) => {
        dispatch(deleteColorTC(id, categoryId));
    };
    const updateColour = (name: string, code: string, item?: IColor) => {
        if (item)
            dispatch(updateColorTC(item.id, categoryId, name, code));
    };
    const openModal = () => {
        dispatch(commonActions.setIsVisibleModalAC(true))
    }
    return (
        <Layout categoryId={categoryId}
                colours={colours}
                addColour={addColour}
                deleteColour={deleteColour}
                updateColour={updateColour}
                openModal={openModal}
        />
    );
};

import {InferActionsType} from "../store"
import {notification} from "antd"

export type CommonActionsType = InferActionsType<typeof commonActions>
export type CommonReducerType = typeof initialState

export enum NotificationType {
    error = "error",
    success = "success",
    info = "info",
    warning = "warning",
}

const initialState = {
    isConfirmPopUp: false,
    isFetching: true,
    confirmLoading: false,
    isVisibleModal: false as boolean
}

export const CommonReducer = (state = initialState, action: CommonActionsType): CommonReducerType => {
    switch (action.type) {
        case "CONFIRM/SET_IS_FETCHING":
        case "SET_IS_VISIBLE_MODAL":
        case "CONFIRM/SET_CONFIRM_POP_IP": {
            return {...state, ...action.payload,}
        }
        default:
            return state
    }
}

export const commonActions = {
    setConfirmLoading: (confirmLoading: boolean) =>
        ({type: "CONFIRM/SET_CONFIRM_POP_IP", payload: {confirmLoading}} as const),
    setIsVisibleModalAC: (isVisibleModal: boolean) =>
        ({type: "SET_IS_VISIBLE_MODAL", payload: {isVisibleModal}} as const),
    setIsFetchingAC: (isFetching: boolean) =>
        ({type: "CONFIRM/SET_IS_FETCHING", payload: {isFetching}} as const),
}

export const setNotification = (type: NotificationType, message: string, description?: string) => {
    notification[type]({message, description})
}

export const commonThunkCreator = (operation: any, dispatch: any) => {
    let tryCathed = withTryCath(operation, dispatch)
    let isFetching = withIsFetching(tryCathed, dispatch)
    return isFetching()
}

export const withTryCath = (operation: any, dispatch: any) => {
    return async () => {
        try {
            return await operation()
        } catch (e) {
            console.log(e)
        }
        return null
    }
}

export const withIsFetching = (operation: any, dispatch: any) => {
    return async () => {
        dispatch(commonActions.setIsFetchingAC(true))
        await operation()
        dispatch(commonActions.setIsFetchingAC(false))
    }
}

import React from 'react'
import {Redirect, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootStateType} from "../redux/store";

export const PrivateRoute = ({component, ...rest}: any) => {
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    const routeComponent = (props: any) => (
        isAuth
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/login'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};
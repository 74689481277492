import React, {useState} from 'react';
import {Button, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {MODAL_TYPE} from "../../utils/constants";
import {ModalWindow} from "./components/Modal";
import {IColor} from "../../interfaces/Colour";

interface IProps {
    categoryId: number
    colours: IColor[]
    addColour: (name: string, code: string) => void
    deleteColour: (id: number) => void
    updateColour: (name: string, code: string, item?: IColor) => void
    openModal: () => void
}

const {Column} = Table;

export const Layout = ({deleteColour, updateColour, addColour, colours, openModal}: IProps) => {
    const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.ADD)
    const [currentColour, setCurrentColour] = useState<IColor | null>()

    const openModalByTypeHandler = (type: MODAL_TYPE, record?: IColor) => {
        openModal()
        setModalType(type)
        setCurrentColour(record)
    }
    return (
        <>
            <Button style={{marginBottom: 20}}
                    type='primary'
                    onClick={() => openModalByTypeHandler(MODAL_TYPE.ADD)}
            >
                Добавить цвет
            </Button>
            {modalType === MODAL_TYPE.ADD &&
            <ModalWindow title={"Добавить цвет"} onClick={addColour}/>}
            {modalType === MODAL_TYPE.EDIT &&
            <ModalWindow title={"Редактирование размера"} onClick={updateColour} item={currentColour}/>}
            <Table rowKey='id' dataSource={colours}>
                <Column title='ID' dataIndex='id' key='id'/>
                <Column title='Цвет' dataIndex='name' key='name'/>
                <Column title='Цвет' dataIndex='code' key='code'
                        render={(text, record: IColor) => {
                            if (record.code) {
                                return <div style={{
                                    width: 30,
                                    border: '1px solid #000',
                                    height: 30,
                                    backgroundColor: record.code || "white"
                                }}/>
                            } else {
                                return <div>N/A</div>
                            }
                        }}
                />
                <Column title='Action' key='action'
                        render={(text, record: IColor) => (
                            <Space size='middle' key={record.id}>
                                <Button icon={<EditOutlined/>}
                                        type={"primary"}
                                        onClick={() => openModalByTypeHandler(MODAL_TYPE.EDIT, record)}
                                />
                                <Button icon={<DeleteOutlined/>}
                                        onClick={() => deleteColour(record.id)}
                                        type={"primary"} danger
                                />
                            </Space>
                        )}
                />
            </Table>
        </>
    );
};

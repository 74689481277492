import { instance } from "../instance";

export const colourAPI = {
  getColours(categoryId: number) {
    return instance.get(`colour?categoryId=${categoryId}`);
  },
  addColour(categoryId: number, name: string, code: string) {
    return instance.post("colour", {categoryId, name, code });
  },
  updateColour(id: number, categoryId: number, name: string, code: string) {
    return instance.put(`colour/${id}`, { categoryId, name, code });
  },
  deleteColour(id: number) {
    return instance.delete(`colour/${id}`);
  },
  getColour(id: number) {
    return instance.get(`colour/${id}`);
  },
};

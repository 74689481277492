export const dateToTimeStamp = (date?: string | undefined) => {
    return date
        ? Number(Date.parse(date).toString().slice(0, -3))
        : 0
}
export const getNowTimeStamp = () => {
    return Number(Date.now().toString().slice(0, -3))
}

export const transformDate = (date: string) => {
    return new Date(date).toLocaleString()
}

import { instance } from "../instance";

export const sizeAPI = {
  getSizes(categoryId: number) {
    return instance.get(`size?categoryId=${categoryId}`)
  },
  addSize(name: string, categoryId: number) {
    return instance.post(`size`, { name,categoryId  })
  },
  getSize(sizeId: number) {
    return instance.get(`size?${sizeId}`)
  },
  updateSize(sizeId: number, name: string, categoryId: number) {
    return instance.put(`size/${sizeId}`, { name,categoryId  })
  },
  deleteSize(sizeId: number) {
    return instance.delete(`size/${sizeId}`);
  },
}

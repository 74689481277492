import {BaseThunkType, InferActionsType} from "../store"
import {priceAPI} from "../../api/admin/priceAPI"
import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer"
import {findItemTC} from "./itemsReducer"
import {uploadAPI} from "../../api/admin/uploadAPI"
import {getSizesTC} from "./sizeReducer"
import {getColoursTC} from "./colourReducer"
import {INewPrice, IPrice} from "../../interfaces/Price";
import { IColor } from "../../interfaces/Colour"

type ThunkType = BaseThunkType<ActionsType>
export type PriceReducerType = typeof initialState
export type ActionsType = InferActionsType<typeof priceActions>

const initialState = {
    prices: [] as IPrice[],
    uniqueColors: [] as IColor[]
}

export const PriceReducer = (state = initialState, action: ActionsType): PriceReducerType => {
    switch (action.type) {
        case "SET-ITEMS-PRICES":
            return {...state, prices: action.payload.prices,}
         case "SET-UNIQ-COLORS":
             return {...state, uniqueColors: action.payload.uniqueColors || []}
        default:
            return state
    }
}

export const priceActions = {
    setPricesItemsAC: (prices: IPrice[]) =>
        ({type: "SET-ITEMS-PRICES", payload: {prices}} as const),
    setUniqColorsAC: (uniqueColors: IColor[]) =>
        ({type: "SET-UNIQ-COLORS", payload: {uniqueColors}} as const),
}

export const setUniqColors = () => (dispatch: any, getState: any) => {
    const state = getState()
    const colorsId = state.price.prices.map((p: any) => p.colourId)
    const uniqColoursId = colorsId.filter((item: any, pos: any) => colorsId.indexOf(item) === pos)
    const uniqueColors = state.colour.colours.filter((product: any) => uniqColoursId.find((i: any) => i === product.id))
    dispatch(priceActions.setUniqColorsAC(uniqueColors))
}

export const getItemPriceTC = (itemId: number, categoryId?: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const prices = await priceAPI.getPrices(itemId);
        dispatch(priceActions.setPricesItemsAC(prices.data));
        await dispatch(findItemTC(itemId))
        if(categoryId){
            await dispatch(getSizesTC(categoryId))
            await dispatch(getColoursTC(categoryId))
        }
        dispatch(setUniqColors())
    }, dispatch);
};
export const addItemPriceTC = (newPrice: INewPrice): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const result = await priceAPI.addPrice(newPrice);
        await dispatch(getItemPriceTC(result.data.itemId));
        setNotification(NotificationType.success, "Цена успешно добавлена");
    }, dispatch);
};
export const getItemPriceDetailsTC = (itemId: number, localDateTime: string = ""): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await priceAPI.getPriceDetails(itemId, localDateTime);
    }, dispatch);
};
export const removePriceTC = (itemPriceId: number, itemId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await priceAPI.removeSale(itemPriceId);
        await dispatch(getItemPriceTC(itemId));
        setNotification(NotificationType.success, "Цена успешно удалена");
    }, dispatch);
};
export const addImageToPriceTC = (colorId: number, photo: any, itemId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const imageId = await uploadAPI.uploadImages(photo)
        await priceAPI.addImage(colorId, imageId.data.id, itemId)
        await dispatch(findItemTC(itemId))
        setNotification(NotificationType.success, "Изображение успешно добавлено");
    }, dispatch)
}

export const deleteImageFromPriceTC = (imageId: number, itemId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await priceAPI.deleteImage(imageId)
        await dispatch(findItemTC(itemId))
        setNotification(NotificationType.success, "Изображение успешно удалено");
    }, dispatch)
}
export const changeMailImageTC = (imageId: number, itemId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await priceAPI.changeMainImage(itemId, imageId)
        await dispatch(findItemTC(itemId))
        setNotification(NotificationType.success, "Главное изображение успешно изменено");
    }, dispatch)
}



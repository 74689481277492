import {instance} from "../instance"
import {INewPrice} from "../../interfaces/Price";

export const priceAPI = {
    getPrices(itemId: number) {
        return instance.get(`item/price/admin?itemId=${itemId}`)
    },
    addPrice(newPrice: INewPrice) {
        return instance.post(`item/price`, newPrice)
    },
    getPriceDetails(itemId: number, localDateTime: string = "") {
        return instance.get(`item/price/${itemId}/details?localDateTime=${localDateTime}`)
    },
    removeSale(itemPriceId: number) {
        return instance.post(`item/price/${itemPriceId}/removesale`)
    },
    addImage(colorId: number, imageId: number, itemId: number) {
        return instance.put(`item/photo?colourId=${colorId}&fileId=${imageId}&itemId=${itemId}`)
    },
    deleteImage(photoId: number) {
        return instance.delete(`item/photo?photoId=${photoId}`)
    },
    changeMainImage(itemId: number, photoId: number) {
        return instance.post(`item/${itemId}/photo/main/${photoId}`)
    }
};

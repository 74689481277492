import {instance} from "../instance"

export const itemsAPI = {
    getItems(categoryId: number) {
        return instance.get(`item?categoryId=${categoryId}&size=${1000}&paged=${1}`)
    },
    addItem(name: string, description: string, categoryId: number) {
        return instance.post("item", {name, description, categoryId})
    },
    updateItem(id: number, name: string, description: string, categoryId: number, orderPoint: number) {
        return instance.put(`item/${id}`, {name, description, categoryId, orderPoint})
    },
    deleteItem(id: number) {
        return instance.delete(`item/${id}`)
    },
    findItem(id: number) {
        return instance.get(`item/${id}`)
    },
    swapItems(firstId: number, secondId: number) {
        return instance.post(`item/swap/${firstId}/${secondId}`)
    },
    search(searchValue: string) {
        return instance.get(`item/search?searchValue=${searchValue}`)
    }
}


import {instance} from "../instance"

export interface INewOrderData {
    comment: string
    serviceDelivery: string
    trackNumber: string
}

export const orderApi = {
    getOrders(username?: string) {
        return instance.get(`order/admin?size=100&username=${username}`)
    },
    getOrder(order_id: number) {
        return instance.get(`order/${order_id}/admin`)
    },
    updateOrder(order_id: number, orderData: INewOrderData) {
        return instance.put(`order/${order_id}/admin?comment=${orderData.comment}&serviceDelivery=${orderData.serviceDelivery}&trackNumber=${orderData.trackNumber}`)
    }
};

export const menuItems = [
    {name: 'Товары', link: '/admin/items'},
    {name: 'Размеры', link: '/admin/size'},
    {name: 'Цвета', link: '/admin/colour'},
    {name: 'Заказы', link: '/admin/shop/orders'},
    {name: 'Клиенты', link: '/admin/shop/customers'},
]

export enum MODAL_TYPE {
    ADD = "add",
    EDIT = "edit"
}

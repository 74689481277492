import {BaseThunkType, InferActionsType} from "../store";
import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer";
import {authAPI} from "../../api/admin/authAPI";

type ThunkType = BaseThunkType<ActionsType>;
export type ActionsType = InferActionsType<typeof authActions>;
export type AuthReducerType = typeof initialState;

export interface isAuthDataType {
    auth: boolean
    username: string
    role: string
}

const initialState = {
    isAuth: false as boolean,
    username: '' as string,
    role: '' as string
};

export const authReducer = (state = initialState, action: ActionsType): AuthReducerType => {
    switch (action.type) {
        case "SET_IS_AUTH_DATA":
            return {
                ...state,
                ...action.payload.isAuthData,
                isAuth: action.payload.isAuthData.auth
            }
        default:
            return state;
    }
};

export const authActions = {
    setIsAuthDataAC: (isAuthData: isAuthDataType) =>
        ({type: "SET_IS_AUTH_DATA", payload: {isAuthData}} as const),
};

export const loginTC = (username: string, password: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const login = await authAPI.login(username, password);
        if (login.data.success) {
            await dispatch(authMeTC());
        } else {
            setNotification(NotificationType.error, "Неверный логин или пароль");
        }
    }, dispatch);
};
export const authMeTC = (): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const authMeData = await authAPI.authMe();
        dispatch(authActions.setIsAuthDataAC(authMeData.data));
    }, dispatch);
};
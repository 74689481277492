import React, {ChangeEvent, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {Button, Input, Modal, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {commonActions, NotificationType, setNotification} from "../../../redux/reducers/commonReducer";
import {ICategory} from "../../../interfaces/Category";

interface IProps {
    title: string
    item?: ICategory | null
    onClick: (name: string, description: string,fileId: number, id?: number, orderPoint?: number) => void
}

const {TextArea} = Input

export const ModalWindow = ({title, item,onClick}:IProps) => {
    const dispatch = useDispatch();
    const isVisibleModal = useSelector<RootStateType, boolean>(state => state.common.isVisibleModal);

    const [fileList, setFileList] = useState<any[]>();
    const [fileId, setFileId] = useState<number | null>()
    const [name, setName] = useState<string>(item?.name || "")
    const [description, setDescription] = useState<string>( item?.description || "")

    const handleOk = () => {
        if(name && description && fileId){
            onClick(name, description,fileId, item?.id, item?.orderPoint)
            dispatch(commonActions.setIsVisibleModalAC(false))
            clearData()
        } else {
            setNotification(NotificationType.error, "Ошибка", "Все поля должны быть заполнены")
        }

    }
    const handleCancel = () => {
        clearData()
        dispatch(commonActions.setIsVisibleModalAC(false))
    }

    const clearData = () => {
        setName('')
        setDescription('')
        setFileList([])
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.currentTarget.value)
    }
    const uploadImg = async (photo: any) => {
        setFileList(photo.fileList);
        if (photo.file.status === 'done') {
            setFileId(photo.fileList?.[0]?.response?.id)
            return setNotification(NotificationType.success, "Фотография загружена")
        } else if (photo.file.status === 'error') {
            return setNotification(NotificationType.error, "Ошибка", "Не удалось загрузить фотографию")
        }
    }
    if(!isVisibleModal){
        return null
    }
    return (
        <Modal title={title} visible={isVisibleModal}  onOk={handleOk}
               onCancel={handleCancel}>
            <Input required value={name} style={{marginBottom: 20}} placeholder='Имя'
                   onChange={onChange}/>
            <TextArea style={{marginBottom: 20}} required value={description} autoSize={{minRows: 2, maxRows: 80}}
                      placeholder='Описание'
                      allowClear onChange={onChangeTextArea}/>
            <Upload listType="text"
                    action={'/api/files'}
                    fileList={fileList}
                    maxCount={1}
                    onChange={(photo) => uploadImg(photo)}
            >
                <Button style={{marginTop: 20}} type='primary' icon={<UploadOutlined/>}>
                    Добавить изображение
                </Button>
            </Upload>
        </Modal>
    );
};

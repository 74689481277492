import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import { RouteParams } from "../../../layout";
import {Button, Space, Table} from "antd";
import {getOrderTC, updateOrderTC} from "../../../../redux/reducers/orderReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from '../../../../redux/store';
import {EditOutlined} from "@ant-design/icons";
import {OrderModal} from './OrderModal';
import {INewOrderData} from "../../../../api/admin/orderApi";
import {IOrderAdmin} from "../../../../interfaces/Order";

export const Order = () => {
    const {Column} = Table;


    const {orderUrlId} = useParams<RouteParams>();
    const order_id = Number(orderUrlId);
    const [isShowModal, setIsShowModal] = useState(false)
    const order = useSelector<RootStateType, IOrderAdmin>(state => state.orders.order)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOrderTC(order_id))
    }, [dispatch, order_id])

    const showModal = (item: IOrderAdmin) => {
        setIsShowModal(true)
    }

    const updateOrder = (orderData: INewOrderData) => {
        dispatch(updateOrderTC(order_id, orderData))
    }

    return (
        <>
            {isShowModal && <OrderModal isShowModal={isShowModal}
                                        setIsShowModal={setIsShowModal}
                                        title={"Изменение заказа"}
                                        onClick={updateOrder}/>}

            {
                order && <Table rowKey='id' pagination={false} dataSource={[order]}>
                    <Column title='Номер трека' dataIndex='trackNumber' key='trackNumber'/>
                    <Column title='Имя'
                            dataIndex='address'
                            key='address'
                            render={(name, key: IOrderAdmin) => (
                                <>{key.address?.username}</>
                            )}
                    />
                    <Column title='Коментарий'
                            dataIndex='comment'
                            key='comment'
                    />
                    <Column title='Способ доставки'
                            dataIndex='serviceDelivery'
                            key='serviceDelivery'
                    />
                    <Column title='Описание'
                            dataIndex='address'
                            key='address'
                            render={(name, key: IOrderAdmin) => (
                                <>{key.address?.description && key.address?.description}</>
                            )}
                    />
                    <Column title='Адрес'
                            dataIndex='address'
                            key='address'
                            render={(name, key: IOrderAdmin) => (
                                <>
                                    <>{key.address?.city && "Город:" + key.address.city}</>
                                    <>{key.address?.country && "Страна:" + key.address.country}</>
                                    <>{key.address?.state && "," + key.address.state}</>
                                </>

                            )}
                    />
                    <Column title='Телефон'
                            dataIndex='address'
                            key='address'
                            render={(name, key: IOrderAdmin) => (
                                <>{key.address?.phone}</>
                            )}
                    />
                    <Column title='Количество' dataIndex='amount' key='amount'/>
                    <Column title='Дата' dataIndex='timeAdd' key='timeAdd'/>
                    <Column title='Статус'
                            dataIndex='status'
                            key='status'
                    />
                    <Column
                        title='Action'
                        key='action'
                        render={(text, item: IOrderAdmin) => (
                            <Space size='middle' key={item.id}>
                                <Button
                                    icon={<EditOutlined/>}
                                    onClick={() => showModal(item)}
                                    type={"primary"}
                                />
                            </Space>
                        )}
                    />
                </Table>
            }
        </>
    )
}


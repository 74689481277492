import React, {useState} from 'react';
import {Tabs, Typography} from "antd";
import {MODAL_TYPE} from "../../utils/constants";
import {ModalWindow} from "./components/Modal";
import {PriceItems} from "./components/PriceItems";
import {PriceImages} from "./components/PriceImages";
import {UploadRequestOption as RcCustomRequestOptions} from "rc-upload/lib/interface";
import { IItem } from '../../interfaces/Item';
import {INewPrice, IPrice } from '../../interfaces/Price';
import {ISize } from '../../interfaces/Size';
import { IColor } from '../../interfaces/Colour';

interface IProps {
    item: IItem
    prices: IPrice[]
    sizes: ISize[]
    colours: IColor[]
    uniqueColors: IColor[]
    openModal: () => void
    addPrice: (newPrice: INewPrice) => void
    removePrice: (id: number) => void
    uploadImg: (photo: RcCustomRequestOptions, colorId: number) => void
    deleteImage: (imageId: number) => void
    changeMainImage: (imageId: number) => void
}
const { Title } = Typography;
const {TabPane} = Tabs;

export const Layout = (props: IProps) => {

    const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.ADD)

    const openModalByTypeHandler = (type: MODAL_TYPE) => {
        props.openModal()
        setModalType(type)
    }
    return (
        <>
            {modalType === MODAL_TYPE.ADD  &&
            <ModalWindow title={"Добавить цену"}
                         onClick={props.addPrice}
                         colours={props.colours}
                         sizes={props.sizes}
                         item={props.item}
            />}
            <Title>{props.item.name}</Title>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Цены" key="1">
                    <PriceItems openModalByTypeHandler={openModalByTypeHandler}
                                prices={props.prices}
                                item={props.item}
                                removePrice={props.removePrice}
                                colours={props.colours}
                                sizes={props.sizes}
                    />
                </TabPane>
                <TabPane disabled={props.prices?.length === 0} tab="Изображения" key="2">
                    <PriceImages uploadImg={props.uploadImg}
                                 uniqueColors={props.uniqueColors}
                                 prices={props.prices}
                                 deleteImage={props.deleteImage}
                                 changeMainImage={props.changeMainImage}
                    />
                </TabPane>
            </Tabs>
        </>
    );
};

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCustomersTC, ICustomer} from "../../../../redux/reducers/customerReducer";
import {Table} from "antd";
import {RootStateType} from "../../../../redux/store";
import Search from "antd/lib/input/Search";
import {NavLink} from 'react-router-dom';

export const CustomersTable = () => {

    const {Column} = Table
    const customers = useSelector<RootStateType, ICustomer[]>(state => state.customers.customers)

    const [userEmail, setUserEmail] = useState<string>('')

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCustomersTC(userEmail))
    }, [dispatch, userEmail])

    const onSearch = (searchValue: string) => {
        setUserEmail(searchValue)
    }

    return (
        <>
            <Search
                placeholder='Введите email пользователя'
                enterButton
                onSearch={onSearch}
            />
            <Table rowKey='id' dataSource={customers}>
                <Column title='Имя' dataIndex='firstname' key='firstname'/>
                <Column title='Email'
                        dataIndex='email'
                        key='email'
                        render={(name, key: ICustomer) => (
                            <NavLink to={`/admin/shop/customer/${key.email}`}>{key.email}</NavLink>
                        )}
                />
                <Column title='Телефон'
                        dataIndex='phone'
                        key='phone'
                />
            </Table>
        </>
    );
}
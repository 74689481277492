import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {
    addItemsTC,
    deleteItemsTC,
    getItemsTC,
    ItemsReducerType,
    swapItemsTC,
    updateItemsTC
} from "../../redux/reducers/itemsReducer";
import {useParams} from "react-router-dom";
import {RouteParams} from "../../ui/layout";
import {commonActions, NotificationType, setNotification} from "../../redux/reducers/commonReducer";
import {Layout} from "./Layout";
import {IItems} from "../../interfaces/Item";

export const Items = () => {
    const dispatch = useDispatch();

    const {categoryUrlId} = useParams<RouteParams>();
    const categoryId = Number(categoryUrlId);

    const {items} = useSelector<RootStateType, ItemsReducerType>(state => state.items);

    useEffect(() => {
        dispatch(getItemsTC(categoryId));
    }, [dispatch, categoryId]);

    const openModal = () => {
        dispatch(commonActions.setIsVisibleModalAC(true))
    }

    const deleteItem = (id: number) => {
        dispatch(deleteItemsTC(id, categoryId));
    };

    const addItem = (name: string, description: string) => {
        dispatch(addItemsTC(description, name, categoryId));
    };

    const editItem = (name: string, description: string, item?: IItems) => {
        if (item)
            dispatch(updateItemsTC(item.itemId, name, description, categoryId, item.orderPoint));
    };

    const swapItemUp = (id: number) => {
        const arrID = items.map((i, index) => i.itemId);
        const objId = Object.values(arrID).findIndex(i => i === id);
        if (arrID[0] === id) {
            setNotification(NotificationType.error, "Ошибка", "Категория уже является первой");
        } else {
            dispatch(swapItemsTC(id, arrID[objId - 1], categoryId));
        }
    };

    const swapItemDown = (id: number) => {
        const arrID = items.map(i => i.itemId);
        const objId = Object.values(arrID).findIndex(i => i === id);
        if (arrID[arrID.length - 1] === id) {
            setNotification(NotificationType.error, "Ошибка", "Категория уже является последней");
        } else {
            dispatch(swapItemsTC(id, arrID[objId + 1], categoryId));
        }
    };

    return (
        <Layout items={items}
                categoryId={categoryId}
                openModal={openModal}
                deleteItem={deleteItem}
                swapItemDown={swapItemDown}
                swapItemUp={swapItemUp}
                editItem={editItem}
                addItem={addItem}
        />
    );
};

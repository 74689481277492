import {instanceEmptyPath} from "../instance"
import {ICustomer} from "../../redux/reducers/customerReducer";

export const customerApi = {
    getCustomers(email?: string) {
        return instanceEmptyPath.get(`/api/profile/find/admin?email=${email}`)
    },
    updateCustomer(customerId: string, customer: ICustomer) {
        return instanceEmptyPath.post(`/api/profile/admin?userId=${customerId}`, customer)
    }
};
import React, {useEffect} from 'react'
import '../App.css'
import {Navbar} from "./2-navbar/Navbar";
import {Layout, Spin} from "antd";
import {HeaderComponent} from "./1-header/Header";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../redux/store";
import {Switch, useLocation} from "react-router-dom";
import {getCategoriesTC} from "../redux/reducers/categoriesReducer";
import {PrivateRoute} from "../utils/PrivateRoute";
import {OrdersTable} from "./3-content/admin/orders/OrdersTable";
import {Order} from "./3-content/admin/orders/Order";
import {CustomersTable} from "./3-content/admin/customers/CustomersTable";
import {SearchResults} from "./3-content/search/searchResults";
import {Customer} from "./3-content/admin/customers/Customer";

import {Categories, Items, Price, Colours , Sizes} from '../pages'

export type RouteParams = {
    path?: string;
    categoryUrlId?: string;
    itemUrlId?: string;
    orderUrlId?: string
    customerUrlId?: string
};

export const LayoutComponent = () => {
    const {Content} = Layout;
    const location = useLocation();
    const dispatch = useDispatch()
    const isFetching = useSelector<RootStateType, boolean>(state => state.common.isFetching)

    useEffect(() => {
        sessionStorage.setItem('url', location.pathname)
    }, [location])

    useEffect(() => {
        dispatch(getCategoriesTC())
    }, [dispatch])

    return (
        <Layout>
            <Navbar/>
            <Layout className='site-layout'>
                <HeaderComponent/>
                <Spin spinning={isFetching}>
                    <Content style={{margin: "24px 16px 0", height: "100%", overflow: "initial"}}>
                        <div className='site-layout-background'>
                            <Switch>
                                <PrivateRoute exact path={"/admin/:path"} component={Categories}/>
                                <PrivateRoute path={"/admin/items/:categoryUrlId"} component={Items}/>
                                <PrivateRoute path={"/admin/price/:categoryUrlId/:itemUrlId"} component={Price}/>
                                <PrivateRoute path={"/admin/size/:categoryUrlId"} component={Sizes}/>
                                <PrivateRoute path={"/admin/colour/:categoryUrlId"} component={Colours}/>
                                <PrivateRoute path={"/admin/shop/orders"} component={OrdersTable}/>
                                <PrivateRoute path={"/admin/shop/order/:orderUrlId"} component={Order}/>
                                <PrivateRoute path={"/admin/shop/customers"} component={CustomersTable}/>
                                <PrivateRoute path={"/admin/shop/customer/:customerUrlId"} component={Customer}/>
                                <PrivateRoute path={"/admin/search/results"} component={SearchResults}/>
                            </Switch>
                        </div>
                    </Content>
                </Spin>
            </Layout>
        </Layout>
    )
}

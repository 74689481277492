import React, {ChangeEvent, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Input, Modal} from "antd";
import {commonActions, NotificationType, setNotification} from "../../../redux/reducers/commonReducer";
import {RootStateType} from "../../../redux/store";
import {IItems} from "../../../interfaces/Item";

interface IProps {
    title: string
    item?: IItems | null
    onClick: (name: string, description: string, item?: IItems) => void
}
const {TextArea} = Input

export const ModalWindow = ({title, item, onClick}:IProps) => {
    const dispatch = useDispatch();
    const isVisibleModal = useSelector<RootStateType, boolean>(state => state.common.isVisibleModal);
    const [name, setName] = useState<string>(item?.name || "")
    const [description, setDescription] = useState<string>( item?.description || "")

    const handleOk = () => {
        if(name && description){
            item ?  onClick(name, description, item) :  onClick(name, description)
            dispatch(commonActions.setIsVisibleModalAC(false))
            clearData()
        } else {
            setNotification(NotificationType.error, "Ошибка", "Все поля должны быть заполнены")
        }

    }
    const handleCancel = () => {
        clearData()
        dispatch(commonActions.setIsVisibleModalAC(false))
    }

    const clearData = () => {
        setName('')
        setDescription('')
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.currentTarget.value)
    }

    if(!isVisibleModal){
        return null
    }
    return (
        <Modal title={title} visible={isVisibleModal}  onOk={handleOk}
               onCancel={handleCancel}>
            <Input required
                   value={name}
                   style={{marginBottom: 20}}
                   placeholder='Имя'
                   allowClear
                   onChange={onChange}
            />
            <TextArea required
                      value={description}
                      autoSize={{minRows: 2, maxRows: 80}}
                      style={{marginBottom: 20}}
                      placeholder='Описание'
                      allowClear
                      onChange={onChangeTextArea}
            />
        </Modal>
    );
};

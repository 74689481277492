import React, {useState} from 'react';
import {Button, Tabs, Upload} from "antd";
import {UploadRequestOption as RcCustomRequestOptions} from "rc-upload/lib/interface";
import {UploadOutlined} from "@ant-design/icons";
import {PriceTabImages} from "./PriceTabImages";
import {IPrice} from "../../../interfaces/Price";
import { IColor } from '../../../interfaces/Colour';

interface IProps {
    prices: IPrice[]
    uniqueColors: IColor[]
    uploadImg: (photo: RcCustomRequestOptions, colorId: number) => void
    deleteImage: (imageId: number) => void
    changeMainImage: (imageId: number) => void
}

const {TabPane} = Tabs;

export const PriceImages = ({prices, uniqueColors, uploadImg, deleteImage, changeMainImage}: IProps) => {

    const [currentColour, setCurrentColour] = useState<number>(uniqueColors[0].id)

    const addImage = (photo: RcCustomRequestOptions) => {
        uploadImg(photo, currentColour)
    }
    const changeCurrentColorHandler = (key: string) => {
        setCurrentColour(+key)
    }

    return (
        <>
            <Upload fileList={[]} customRequest={addImage}>
                <Button disabled={!prices.length}
                        style={{marginBottom: 20}}
                        type='primary'
                        icon={<UploadOutlined/>}
                >
                    Добавить изображение
                </Button>
            </Upload>
            <Tabs tabPosition={'left'} onChange={changeCurrentColorHandler}>
                {uniqueColors.map(color => <TabPane tab={color.name} key={color.id}>
                        <PriceTabImages deleteImage={deleteImage}
                                        changeMainImage={changeMainImage}
                                        uniqueColor={color}
                        />
                    </TabPane>
                )}
            </Tabs>
        </>
    );
};

import React, {ChangeEvent, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Input, Modal} from "antd";
import {commonActions, NotificationType, setNotification} from "../../../redux/reducers/commonReducer";
import {RootStateType} from "../../../redux/store";
import { ISize } from '../../../interfaces/Size';

interface IProps {
    title: string
    item?: ISize | null
    onClick: (name: string, item?: ISize) => void
}

export const ModalWindow = ({title, item, onClick}:IProps) => {
    const dispatch = useDispatch();
    const isVisibleModal = useSelector<RootStateType, boolean>(state => state.common.isVisibleModal);
    const [name, setName] = useState<string>(item?.name || "")

    const handleOk = () => {
        if(name){
            item ?  onClick(name, item) :  onClick(name)
            dispatch(commonActions.setIsVisibleModalAC(false))
            clearData()
        } else {
            setNotification(NotificationType.error, "Ошибка", "Все поля должны быть заполнены")
        }
    }
    const handleCancel = () => {
        clearData()
        dispatch(commonActions.setIsVisibleModalAC(false))
    }

    const clearData = () => {
        setName('')
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    if(!isVisibleModal){
        return null
    }
    return (
        <Modal title={title}
               visible={isVisibleModal}
               onOk={handleOk}
               onCancel={handleCancel}
        >
            <Input autoFocus
                   required
                   value={name}
                   style={{marginBottom: 20}}
                   placeholder='Имя'
                   allowClear
                   onChange={onChange}
            />
        </Modal>
    );
};

import React, {useState} from "react";
import {Avatar, Button, Layout, Popover} from "antd";
import {UserOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {useHistory} from "react-router-dom";


export const HeaderComponent = () => {
    const {Header} = Layout;
    const history = useHistory()
    const username = useSelector<RootStateType, string>(state => state.auth.username)
    const [isShowPopOver, setIsShowPopOver] = useState<boolean>(false)

    const isShowPopOverHandler = () => {
        setIsShowPopOver(!isShowPopOver)
    }
    const logOut = () => {
        history.push('/logout')
        sessionStorage.setItem('url', '/admin/items')
    }

    return (
        <Header className='site-layout-header'>
            <Popover
                placement="bottomRight"
                content={<Button onClick={logOut} type={"link"}>Выйти</Button>}
                title={username}
                trigger="click"
                visible={isShowPopOver}
                onVisibleChange={isShowPopOverHandler}
            >
                <Avatar size='large' icon={<UserOutlined/>}/>
            </Popover>
        </Header>
    );
}

import React from 'react';
import {Button, Space, Table} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {MODAL_TYPE} from "../../../utils/constants";
import {dateToTimeStamp, getNowTimeStamp, transformDate} from "../../../utils/dateUtils";
import {IPrice} from "../../../interfaces/Price";
import { IItem } from '../../../interfaces/Item';
import { ISize } from '../../../interfaces/Size';
import { IColor } from '../../../interfaces/Colour';

interface IProps {
    prices: IPrice[]
    item: IItem
    openModalByTypeHandler: (type: MODAL_TYPE) => void
    sizes: ISize[]
    colours: IColor[]
    removePrice: (id: number) => void
}

const {Column} = Table
export const PriceItems = ({prices, openModalByTypeHandler, sizes, colours, removePrice, item}: IProps) => {

    return (
        <>
            <Button style={{marginBottom: 20}}
                    type='primary'
                    onClick={() => openModalByTypeHandler(MODAL_TYPE.ADD)}>
                Добавить цену
            </Button>
            <Table rowKey={"itemPriceId"} dataSource={prices}>
                <Column title='ID' dataIndex='itemPriceId' key='itemPriceId'/>
                <Column title='Наименование товара' key='itemId' render={() => <div>{item.name}</div>}/>
                <Column title='Цена за 1 шт' dataIndex='amount' key='amount'/>
                <Column title='Кол-во на складе' dataIndex='count' key='count'/>
                <Column title='Цвет' key='colourId' render={(text, record: IPrice) => {
                    let color = colours.find(c => c.id === record.colourId)
                    return <div>{color?.name || 'n/a'}</div>
                }}
                />
                <Column title='Размер' key='sizeId' render={(text, record: IPrice) => {
                    const size = sizes.find(s => s.id === record.sizeId)
                    return <div>{size?.name || 'n/a'}</div>
                }}
                />
                <Column title='Актуальна с'
                        dataIndex='priceStartTime'
                        key='priceStartTime'
                        render={(text, record: IPrice) => (
                            <div>{transformDate(record.priceStartTime)}</div>
                        )}
                />
                <Column title='Актуальна до'
                        dataIndex='priceEndTime'
                        key='priceEndTime'
                        render={(text, record: IPrice) => (
                            <div>{transformDate(record.priceEndTime)}</div>
                        )}
                />
                <Column title='Action' key='action' render={(text, record: IPrice) => (
                    <Space size='middle' key={record.itemPriceId}>
                        {getNowTimeStamp() < dateToTimeStamp(record.priceEndTime)
                            ? <Button icon={<DeleteOutlined/>}
                                      type={"primary"}
                                      danger
                                      onClick={() => removePrice(record.itemPriceId)}
                            />
                            : 'Товар удален'
                        }

                    </Space>
                )}
                />
            </Table>
        </>
    );
};

import {categoriesAPI} from "../../api/admin/categoryAPI"
import {commonThunkCreator, NotificationType, setNotification} from "./commonReducer"
import {BaseThunkType, InferActionsType} from "../store"
import {ICategory} from "../../interfaces/Category";

type ThunkType = BaseThunkType<ActionsType>
export type ActionsType = InferActionsType<typeof categoryActions>
export type ICategoryReducer = typeof initialState

const initialState = {
    categories: [] as ICategory[],
};

export const categoriesReducer = (state = initialState, action: ActionsType): ICategoryReducer => {
    switch (action.type) {
        case "SET-CATEGORIES":
            return {...state, categories: action.payload,}
        default:
            return state
    }
};

export const categoryActions = {
    setCategoriesAC: (categories: ICategory[]) =>
        ({type: "SET-CATEGORIES", payload: categories,} as const),
}

export const getCategoriesTC = (): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const categories = await categoriesAPI.getCategories()
        dispatch(categoryActions.setCategoriesAC(categories))
    }, dispatch)
}
export const addCategoryTC = (name: string, description: string,fileId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await categoriesAPI.addCategory(name, description, fileId)
        await dispatch(getCategoriesTC())
        setNotification(NotificationType.success, "Категория успешно добавлена")
    }, dispatch)
}
export const updateCategoryTC = ( name: string, description: string,fileId: number, id: number,orderPoint: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await categoriesAPI.updateCategory(id, name, description,fileId, orderPoint)
        await dispatch(getCategoriesTC())
        setNotification(NotificationType.success, "Категория успешно ищменена")
    }, dispatch)
}
export const deleteCategoryTC = (id: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await categoriesAPI.deleteCategory(id)
        await dispatch(getCategoriesTC())
        setNotification(NotificationType.success, "Категория успешно удалена")
    }, dispatch)
}

export const swapCategoriesTC = (firstId: number, secondId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await categoriesAPI.swapCategory(firstId, secondId)
        await dispatch(getCategoriesTC())
    }, dispatch)
}

import React, {ChangeEvent, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Input, Modal} from "antd";
import {commonActions, NotificationType, setNotification} from "../../../redux/reducers/commonReducer";
import {RootStateType} from "../../../redux/store";
import {HexColorPicker} from "react-colorful";
import {IColor} from "../../../interfaces/Colour";

interface IProps {
    title: string
    item?: IColor | null
    onClick: (name: string, code: string, item?: IColor) => void
}

export const ModalWindow = ({title, item, onClick}:IProps) => {
    const dispatch = useDispatch();
    const isVisibleModal = useSelector<RootStateType, boolean>(state => state.common.isVisibleModal);
    const [name, setName] = useState<string>(item?.name || "")
    const [code, setCode] = useState<string>( item?.code || "")

    const handleOk = () => {
        if(name && code){
            item ?  onClick(name, code, item) :  onClick(name, code)
            dispatch(commonActions.setIsVisibleModalAC(false))
            clearData()
        } else {
            setNotification(NotificationType.error, "Ошибка", "Все поля должны быть заполнены")
        }
    }
    const handleCancel = () => {
        clearData()
        dispatch(commonActions.setIsVisibleModalAC(false))
    }

    const clearData = () => {
        setName('')
        setCode('')
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    if(!isVisibleModal){
        return null
    }
    return (
        <Modal title={title} visible={isVisibleModal}  onOk={handleOk}
               onCancel={handleCancel}>
            <Input autoFocus
                   required
                   value={name}
                   style={{marginBottom: 20}}
                   placeholder='Имя'
                   allowClear
                   onChange={onChange}
            />
            <Input placeholder="Код цвета"
                   style={{marginBottom: 20}}
                   value={code}
                   disabled
                   required
            />
            <HexColorPicker color={code} onChange={setCode}/>
        </Modal>
    );
};

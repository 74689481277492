import React, {ChangeEvent} from "react";
import {Input, Modal, Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {NotificationType, setNotification} from "../../redux/reducers/commonReducer";
import {RootStateType} from "../../redux/store";
import {customersActions, ICustomer} from "../../redux/reducers/customerReducer";

type ModalWindowType = {
    customer: ICustomer
    title: string
    isShow: boolean
    setIsShow: (value: boolean) => void
    onClick: () => void
};

export const ModalWindowCustomer = (props: ModalWindowType) => {

    const confirmLoading = useSelector<RootStateType, boolean>(state => state.common.confirmLoading)
    const dispatch = useDispatch()

    const onChangeFirstname = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(customersActions.setDataCustomer({...props.customer, firstname: e.currentTarget.value}));
    };
    const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(customersActions.setDataCustomer({...props.customer, lastname: e.currentTarget.value}));
    };
    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(customersActions.setDataCustomer({...props.customer, email: e.currentTarget.value}));
    };
    const onChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(customersActions.setDataCustomer({...props.customer, phone: e.currentTarget.value}));
    };
    const onChangeBirthDate = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(customersActions.setDataCustomer({...props.customer, birthDate: e.currentTarget.value}));
    };
    const changeAccountNonExpired = (checked: boolean) => {
        dispatch(customersActions.setDataCustomer({...props.customer, accountNonExpired: checked}));
    }

    const changeAccountNonLocked = (checked: boolean) => {
        dispatch(customersActions.setDataCustomer({...props.customer, accountNonLocked: checked}));
    }

    const changeCredentialsNonExpired = (checked: boolean) => {
        dispatch(customersActions.setDataCustomer({...props.customer, credentialsNonExpired: checked}));
    }

    const handleOk = () => {
        if (props.customer.firstname?.trim() === "" || props.customer.phone?.trim() === "") {
            return setNotification(NotificationType.error, "Ошибка", "Все поля должны быть заполнены")
        }
        props.onClick()
        props.setIsShow(false)
    }
    const handleCancel = () => props.setIsShow(false)


    return (
        <Modal title={props.title} visible={props.isShow} onOk={handleOk} confirmLoading={confirmLoading}
               onCancel={handleCancel}>
            <Input required value={props.customer.firstname} style={{marginBottom: 20}} placeholder='Имя' allowClear
                   onChange={onChangeFirstname}/>
            <Input required value={props.customer.lastname} style={{marginBottom: 20}} placeholder='Фамилия' allowClear
                   onChange={onChangeLastName}/>
            <Input required value={props.customer.birthDate} style={{marginBottom: 20}} placeholder='Дата рождения'
                   allowClear onChange={onChangeBirthDate}/>
            <Input required style={{marginBottom: 20}} value={props.customer.email} placeholder='email' allowClear
                   onChange={onChangeEmail}/>
            <Input required value={props.customer.phone} placeholder='Phone' allowClear onChange={onChangePhone}
                   style={{marginBottom: "10px"}}/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <div>
                    <p style={{display: "flex", alignItems: "center"}}>Account non expired:
                        <Switch
                            defaultChecked={props.customer.accountNonExpired}
                            style={{marginLeft: "10px"}}
                            onChange={(value) => changeAccountNonExpired(value)}
                        />
                    </p>
                </div>
                <div>
                    <p style={{display: "flex", alignItems: "center"}}>Account non locked:
                        <Switch
                            defaultChecked={props.customer.accountNonLocked}
                            style={{marginLeft: "15px"}}
                            onChange={(value) => changeAccountNonLocked(value)}
                        />
                    </p>
                </div>
                <div>
                    <p style={{display: "flex", alignItems: "center"}}>Credentials non expired:
                        <Switch
                            defaultChecked={props.customer.credentialsNonExpired}
                            style={{marginLeft: "15px"}}
                            onChange={(value) => changeCredentialsNonExpired(value)}
                        />
                    </p>
                </div>
            </div>
        </Modal>
    )
}
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {RouteParams} from "../../ui/layout";
import {RootStateType} from "../../redux/store";
import {
    addSizeTC,
    deleteSizeTC,
    getSizesTC,
    SizeReducerType,
    updateSizeTC
} from "../../redux/reducers/sizeReducer";
import {Layout} from "./Layout";
import {commonActions} from "../../redux/reducers/commonReducer";
import {ISize} from "../../interfaces/Size";

export const Sizes = () => {
    const dispatch = useDispatch();
    const {categoryUrlId} = useParams<RouteParams>();
    const categoryId = Number(categoryUrlId);

    const {sizes} = useSelector<RootStateType, SizeReducerType>(state => state.size)

    useEffect(() => {
        dispatch(getSizesTC(categoryId))
    }, [dispatch, categoryId])

    const deleteItem = (id: number) => {
        dispatch(deleteSizeTC(id, categoryId))
    }
    const addSize = (name: string) => {
        dispatch(addSizeTC(name ,categoryId))
    }
    const editSize = (name: string, item?: ISize) => {
        if(item)
            dispatch(updateSizeTC(item.id ,name, categoryId))
    }
    const openModal = () => {
        dispatch(commonActions.setIsVisibleModalAC(true))
    }
    return (
       <Layout sizes={sizes}
               deleteItem={deleteItem}
               addSize={addSize}
               editSize={editSize}
               openModal={openModal}
       />
    );
};

import React from 'react';
import {Layout} from "./Layout";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {
    addCategoryTC,
    deleteCategoryTC,
    ICategoryReducer,
    swapCategoriesTC,
    updateCategoryTC
} from "../../redux/reducers/categoriesReducer";
import {useParams} from "react-router-dom";
import {RouteParams} from "../../ui/layout";
import {commonActions, NotificationType, setNotification} from "../../redux/reducers/commonReducer";

export const Categories = () => {
    const dispatch = useDispatch();
    const {categories} = useSelector<RootStateType, ICategoryReducer>(state => state.categories);

    const {path} = useParams<RouteParams>();

    const swapCategoryUp = (id: number) => {
        const arrID = categories.map(i => i.id);
        const objId = Object.values(arrID).findIndex(i => i === id);
        if (arrID[0] === id) {
            setNotification(NotificationType.error, "Ошибка", "Категория уже является первой");
        } else {
            dispatch(swapCategoriesTC(id, arrID[objId - 1]));
        }
    };
    const swapCategoryDown = (id: number) => {
        const arrID = categories.map(i => i.id);
        const objId = Object.values(arrID).findIndex(i => i === id);
        if (arrID[arrID.length - 1] === id) {
            setNotification(NotificationType.error, "Ошибка", "Категория уже является последней");
        } else {
            dispatch(swapCategoriesTC(id, arrID[objId + 1]));
        }
    };

    const addCategory = (name: string, description: string, fileId: number) => {
        dispatch(addCategoryTC(name, description, fileId));
    };
    const editCategory = (name: string, description: string,fileId:number, id: number, orderPoint: number) => {
        dispatch(updateCategoryTC(name, description, fileId, id, orderPoint));
    };
    const deleteCategory = (id: number) => {
        dispatch(deleteCategoryTC(id));
    };
    const openModal = () => {
        dispatch(commonActions.setIsVisibleModalAC(true))
    }

    return (
        <>
            <Layout categories={categories}
                    path={path}
                    openModal={openModal}
                    addCategory={addCategory}
                    editCategory={editCategory}
                    deleteCategory={deleteCategory}
                    swapCategoryUp={swapCategoryUp}
                    swapCategoryDown={swapCategoryDown}
            />
        </>
    );
};

import {instance} from "../instance"

export const categoriesAPI = {
  getCategories() {
    return instance.get("category").then(res => res.data)
  },
  addCategory(name: string, description: string, fileId: number) {
    return instance.post("category", { name, description, fileId })
  },
  updateCategory(id: number, name: string, description: string, fileId: number, orderPoint: number) {
    return instance.put(`category/${id}`, { name, description,fileId, orderPoint })
  },
  deleteCategory(id: number) {
    return instance.delete(`category/${id}`)
  },
  swapCategory(firstId: number, secondId: number) {
    return instance.post(`category/swap/${firstId}/${secondId}`)
  },
}

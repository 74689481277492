import { Action, applyMiddleware, combineReducers, createStore } from "redux"
import thunkMiddleWare, { ThunkAction } from "redux-thunk"
import { categoriesReducer } from "./reducers/categoriesReducer"
import { itemsReducer } from "./reducers/itemsReducer"
import { CommonReducer } from "./reducers/commonReducer"
import { ColourReducer } from "./reducers/colourReducer"
import { SizeReducer } from "./reducers/sizeReducer"
import { PriceReducer } from "./reducers/priceReducer"
import { orderReducer } from "./reducers/orderReducer"
import {authReducer} from "./reducers/authReducer"
import {customerReducer} from "./reducers/customerReducer"

export const reducers = combineReducers({
  categories: categoriesReducer,
  items: itemsReducer,
  common: CommonReducer,
  colour: ColourReducer,
  size: SizeReducer,
  price: PriceReducer,
  auth: authReducer,
  orders: orderReducer,
  customers: customerReducer
});

export const store = createStore(reducers, applyMiddleware(thunkMiddleWare))

type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
export type InferActionsType<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>
export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<R, RootStateType, unknown, A>
export type RootStateType = ReturnType<typeof reducers>;

// @ts-ignore
window.store = store

import React from 'react'
import {useSelector} from "react-redux";
import {PriceTabImageCard} from './PriceTabImageCard';
import {RootStateType} from "../../../redux/store";
import {IColor} from "../../../interfaces/Colour";
import {IItem} from "../../../interfaces/Item";

interface IProps {
    uniqueColor: IColor
    deleteImage: (imageId: number) => void
    changeMainImage: (imageId: number) => void
}

export const PriceTabImages = ({uniqueColor,changeMainImage,deleteImage}: IProps) => {
    const item = useSelector<RootStateType, IItem>(state => state.items.item)
    return (
        <>
            {item.image
                ? <>{item.itemDetails?.map(p => {
                    if (p.colour.id === uniqueColor.id) {
                        return <div key={p.colour.id} className={"images-container"}>
                            {p.photos.map(photo => <PriceTabImageCard key={photo.id}
                                                                      photo={photo}
                                                                      deleteImage={deleteImage}
                                                                      changeMainImage={changeMainImage}
                            />)}
                        </div>
                    } else {
                        return []
                    }
                })}</>
                : 'Нет изображений'}

        </>
    )
}

import React, {useState} from 'react';
import {ImageWithEmpty} from "../../components/Image";
import {NavLink} from "react-router-dom";
import {Button, Space, Table} from "antd";
import {DeleteOutlined, DownOutlined, EditOutlined, UpOutlined} from "@ant-design/icons";
import {ModalWindow} from "./components/Modal";
import {MODAL_TYPE} from "../../utils/constants";
import { ICategory } from '../../interfaces/Category';

interface IProps {
    categories: ICategory[]
    path?: string
    swapCategoryDown: (id: number) => void
    swapCategoryUp: (id: number) => void
    addCategory: any
    editCategory: any
    deleteCategory: (id: number) => void
    openModal: () => void
}

const {Column} = Table;

export const Layout = ({
                           categories,
                           path,
                           swapCategoryDown,
                           swapCategoryUp,
                           addCategory,
                           editCategory,
                           deleteCategory,
                           openModal
                       }: IProps) => {
    const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.ADD)
    const [currentCategory, setCurrentCategory] = useState<ICategory | null>()

    const openModalByTypeHandler = (type: MODAL_TYPE, record?: ICategory ) => {
        openModal()
        setModalType(type)
        setCurrentCategory(record)
    }

    return (
        <>
            {modalType === MODAL_TYPE.ADD  && <ModalWindow title={"Добавить категорию"} onClick={addCategory}/>}
            {modalType === MODAL_TYPE.EDIT  && <ModalWindow title={"Редактировать категорию"} onClick={editCategory} item={currentCategory}/>}
            {isRenderAddButton(openModalByTypeHandler, path)}
            <Table rowKey='id' dataSource={categories}>
                <Column title='ID' dataIndex='id' key='id'/>
                <Column
                    title='Изображение'
                    dataIndex='webPath'
                    key='webPath'
                    render={(text, record: ICategory) => (
                        <ImageWithEmpty path={record.webPath}/>
                    )}
                />
                <Column
                    title='Название категории'
                    dataIndex='name'
                    key='name'
                    render={(name, key: ICategory) => (
                        <NavLink to={`/admin/${path}/${key.id}`}>{name}</NavLink>
                    )}
                />
                <Column title='Описание' dataIndex='description' key='description'/>
                <Column
                    title='Action'
                    key='action'
                    render={(text, record: ICategory) => (
                        <Space size='middle' key={record.id}>
                            <Button
                                icon={<EditOutlined/>}
                                onClick={() => openModalByTypeHandler( MODAL_TYPE.EDIT, record)}
                                type={"primary"}
                            />
                            <Button
                                icon={<DeleteOutlined/>}
                                onClick={() => deleteCategory(record.id)}
                                type={"primary"}
                                danger
                            />
                            <Button
                                icon={<UpOutlined/>}
                                onClick={() => swapCategoryUp(record.id)}
                                type={"primary"}
                            />
                            <Button
                                icon={<DownOutlined/>}
                                onClick={() => swapCategoryDown(record.id)}
                                type={"primary"}
                            />
                        </Space>
                    )}
                />
            </Table>
        </>

    );
};

const isRenderAddButton = (openModalByTypeHandler: (type: MODAL_TYPE) => void, path?: string) => {
    return path === "items"
        ? <Button style={{marginBottom: 20}} type='primary' onClick={() => openModalByTypeHandler(MODAL_TYPE.ADD)}>
            Добавить категорию
        </Button>
        : null
}

import React, {useEffect} from 'react'
import {Form, Input, Button} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import './login.css'
import {useDispatch, useSelector} from "react-redux";
import {authMeTC, loginTC} from '../../../redux/reducers/authReducer';
import {Redirect} from "react-router-dom";
import {RootStateType} from "../../../redux/store";


export const Login = () => {
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    const dispatch = useDispatch()
    const url = sessionStorage.getItem('url')

    const onFinish = (values: any) => {
        dispatch(loginTC(values.username, values.password))
    }

    useEffect(() => {
        !isAuth && dispatch(authMeTC())
    }, [dispatch, isAuth])

    if (isAuth) {
        return <Redirect to={url ? url : '/'}/>
    }

    return (
        <div className={'login'}>
            <div className={'loginContainer'}>
                <Form
                    name="login"
                    className="login-form"
                    initialValues={{remember: false}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Please input your Username!'}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: false, message: 'Please input your Password!'}]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
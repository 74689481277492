import React, {useState} from 'react';
import {DatePicker, Input, InputNumber, Modal, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {commonActions, NotificationType, setNotification} from "../../../redux/reducers/commonReducer";
import {ISize} from "../../../interfaces/Size";
import {INewPrice} from "../../../interfaces/Price";
import { IColor } from '../../../interfaces/Colour';
import { IItem } from '../../../interfaces/Item';

interface IProps {
    title: string
    onClick: (newPrice: INewPrice) => void
    sizes: ISize[]
    colours: IColor[]
    item: IItem
}

const {RangePicker} = DatePicker
const {Option} = Select

export const ModalWindow = ({title, onClick, sizes, colours, item}: IProps) => {
    const dispatch = useDispatch();
    const isVisibleModal = useSelector<RootStateType, boolean>(state => state.common.isVisibleModal);

    const [amount, setAmount] = useState<number>()
    const [count, setCount] = useState<number>()
    const [currentColour, setCurrentColour] = useState<number>()
    const [currentSize, setCurrentSize] = useState<number | undefined>()
    const [startDate, setStartDate] = useState<string | null>(null)
    const [endDate, setEndDate] = useState<string | null>(null)

    const handleOk = () => {
        if (amount && count && currentColour && currentSize && startDate && endDate) {
            onClick({
                amount: amount,
                count: count,
                colourId: currentColour,
                sizeId: currentSize,
                priceStartTime: startDate,
                priceEndTime: endDate,
                itemId: item.itemId})
            dispatch(commonActions.setIsVisibleModalAC(false))
            clearData()
        } else {
            setNotification(NotificationType.error, "Ошибка", "Все поля должны быть заполнены")
        }

    }
    const handleCancel = () => {
        clearData()
        dispatch(commonActions.setIsVisibleModalAC(false))
    }
    const clearData = () => {
        setAmount(undefined)
        setCount(undefined)
        setCurrentColour(undefined)
        setCurrentSize(undefined)
        setStartDate(null)
        setEndDate(null)
    }

    const onChangeAmount = (amount: any) => {
        setAmount(amount)
    }
    const onChangeCount = (count: any) => {
        setCount(count)
    }
    const onChangeColourId = (colourId: number) => {
        setCurrentColour(colourId)
    }
    const onChangeSizeId = (sizeId: any) => {
        setCurrentSize(sizeId)
    }

    const onChangeDate = (values: any, formatString: [string, string]) => {
        setStartDate(formatString[0] + ".560Z")
        setEndDate(formatString[1] + ".560Z")
    }

    if (!isVisibleModal) {
        return null
    }

    return (
        <Modal
            title={title}
            visible={isVisibleModal}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <div>Наименование товара:</div>
            <Input defaultValue={item.name}
                   readOnly
                   style={{width: "100%", marginBottom: 10}}
            />
            <div>Цена товара:</div>
            <InputNumber min={0}
                         value={amount}
                         onChange={onChangeAmount}
                         placeholder={"Цена"}
                         style={{width: "100%", marginBottom: 10}}
            />
            <div>Кол-во товара на складе:</div>
            <InputNumber min={0}
                         value={count}
                         onChange={onChangeCount}
                         placeholder={"Кол-во на складе"}
                         style={{width: "100%", marginBottom: 10}}
            />
            <div>Цвет:</div>
            <Select
                onChange={onChangeColourId}
                value={currentColour}
                style={{width: "100%", marginBottom: 10}}
            >
                {colours.map(c => (
                    <Option key={c.id} value={c.id}>
                        {c.name}
                    </Option>
                ))}
            </Select>
            <div>Размер:</div>
            <Select
                value={currentSize}
                onChange={onChangeSizeId}
                style={{width: "100%", marginBottom: 10}}
            >
                {sizes.map(s => (
                    <Option key={s.id} value={s.id}>
                        {s.name}
                    </Option>
                ))}
            </Select>
            <RangePicker onChange={onChangeDate} format={"YYYY-MM-DDTHH:mm:ss"}/>
        </Modal>
    );
};
